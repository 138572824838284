<template>
  <div>
    <Nav />
    <div class="center land-box">
         <div class="tabs mb20 mt20">
            <el-tabs v-model="activeName" >
                <el-tab-pane label="供需数据" name="first">
                     <div >
                        <div class="mainList">
                            <div class="mainlist_title">
                                <div class="mainlist_title_icon"></div>
                                <h2 class="title">供给信息对比分析</h2>
                            </div>
                            <div class="zh_one">
                                <div >
                                    <el-radio-group v-model="radio">
                                        <el-radio :label="1">行政区域对比</el-radio>
                                        <el-radio :label="2">土地用途对比</el-radio>
                                        <el-radio :label="3">地源趋势分析</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="mj">
                                    <el-radio-group v-model="mj" style="margin-right:15px;">
                                        <el-radio :label="4">面积</el-radio>
                                        <el-radio :label="5">数量</el-radio>
                                    </el-radio-group>  
                                    <el-select v-model="qb" placeholder="请选择">
                                        <el-option
                                             v-for="item in []"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                               
                            </div>
                            <div class="zh_three">
                                <div class="flex-center">
                                     <div id="myChart" style="width: 950px;height: 350px;" ref="myChart"></div>
                                </div>
                            </div>
                        </div>
                        <div class="mainList">
                            <div class="mainlist_title">
                                <div class="mainlist_title_icon"></div>
                                <h2 class="title">需求信息对比分析</h2>
                            </div>
                            <div class="zh_one">
                                <div >
                                    <el-radio-group v-model="radio">
                                        <el-radio :label="1">行政区域对比</el-radio>
                                        <el-radio :label="2">土地用途对比</el-radio>
                                        <el-radio :label="3">地源趋势分析</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="mj">
                                    <el-radio-group v-model="mj" style="margin-right:15px;">
                                        <el-radio :label="4">面积</el-radio>
                                        <el-radio :label="5">数量</el-radio>
                                    </el-radio-group>  
                                    <el-select v-model="qb" placeholder="请选择">
                                        <el-option
                                             v-for="item in []"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div >
                               
                            </div>
                            <div class="zh_three">
                                <div class="flex-center">
                                     <div id="myChart1" style="width: 950px;height: 350px;" ref="myChart"></div>
                                </div>
                            </div>
                        </div>
                     </div>
                </el-tab-pane>
                <el-tab-pane label="交易数据" name="second">配置管理</el-tab-pane>
            </el-tabs>
         </div>
    </div>
    <footer2></footer2>
  </div>
</template>

<script>
 import Nav from '@/components/nav'
 import footer2 from "components/footer.vue";
export default {
    name: 'Land',
    components: {
        Nav,
        footer2
    },
    data(){
        return{
            activeName:'first',
            radio:1,
            mj:4,
            qb:1
        }
    },
    mounted(){
        this.init()
        
    },
    methods:{
        init(){
            // return
            let myChart = this.$echarts.init(document.getElementById('myChart'))
            let myChart1 = this.$echarts.init(document.getElementById('myChart1'))
            let option = {
                // title:'ss',
                xAxis: {
                    type: 'category',
                    data: ['高新区', '锦江区', '天府新区', '金牛区', '青羊区', '广汉市', '德阳市'],
                    width:'40'
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                    data: [120, 200, 150, 80, 70, 110, 130],
                    type: 'bar',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    },
                    barWidth:'10'
                    }
                ]
            };
            myChart.setOption(option);
            myChart1.setOption(option);
        }
    }
}
</script>

<style lang="scss" scoped>

.mainList{
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    .mainlist_title{
        width: 100%;
        height: 64px;
        border-top: 1px solid rgb(238, 237, 237);
        box-sizing: border-box;
        padding: 0px 24px;
        line-height: 64px;
        display: flex;
        .mainlist_title_icon{
            width: 6px;
            height: 21px;
            background: rgb(1, 82, 147);
            border-radius: 3px;
            display: inline-block;
            margin-top: 22px;
            margin-right: 12px;
        }
        .title{
            font-size: 20px;
            color: rgb(51, 51, 51);
            font-weight: 700;
        }
      }
      .zh_one{
        // width: 100%;
        // height: 40px;
        padding-left: 24px;
        .mj{
            
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right:30px ;
            padding-top: 15px;
        }
    }
      .zh_three{
        width: 100%;
        // height: 500px;
    }
}
.flex-center{
    display: flex;
    justify-content: center;
}
</style>

